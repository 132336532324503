import React, { useState, useRef, useEffect } from 'react';
import Icon from '../Icon';
import Button from '../Button';
import dayjs from 'dayjs';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './ObjectEntry.module.scss';
import classNames from 'classnames';
import ShareModal from '../ShareModal';
import InfoBox from '../InfoBox';
import MassInfo from '../MassInfo';
import { trackEvent } from '../../utils/datalayer';
import { ReactComponent as CloseIcon } from '../Icon/icons/close.svg';

const ObjectEntry = React.forwardRef(
    (
        {
            id,
            title,
            href,
            text,
            label,
            image,
            removeItem,
            addToMyList,
            showRemoveButton,
            onClick,
            isMyList,
            isInMyList,
            active,
            infoBox,
            infoBoxText,
            massInfo,
            slug,
            date,
            closestStation
        },
        ref
    ) => {
        const [readMore, setReadMore] = useState(false);
        const textElement = useRef(null);
        const [modalIsOpen, setModalOpen] = useState();
        const [removed, setRemoved] = useState(false);

        const handleClose = (e) => {
            setModalOpen(false);
            e.stopPropagation();
        };

        const handleOpenModal = (e) => {
            setModalOpen(true);
            e.stopPropagation();
        };

        const handleRemoveItem = (id) => {
            setRemoved(true);
            removeItem(id);
        };

        const handleAddToMyList = (e) => {
            addToMyList(id);
            e.stopPropagation();
        };

        const handleReadMore = () => {
            setReadMore(!readMore);
            if (!readMore) {
                trackEvent(
                    'VirtualObjectPageView',
                    'Object',
                    'Read more',
                    slug
                );
            }
        };

        const dangerousText = { __html: text };

        const handleOnClick = (e) => {
            onClick(id);
            e.stopPropagation();
        };

        const hasImage = Boolean(image && image.renditions);
        if (removed) {
            return null;
        }

        const showMoreButton =
            (text && text.length > 30) || infoBox || infoBoxText;

        return (
            <div
                ref={ref}
                aria-expanded={readMore}
                className={classNames(styles['ObjectEntry'], {
                    [styles['ObjectEntry--Active']]: active,
                    [styles['ObjectEntry--Expanded']]: readMore,
                })}>
                <div
                    className={styles['ObjectEntry__Container']}
                    onClick={(e) => handleOnClick(e)}>
                    <div
                        className={styles['ObjectEntry__MobileClickHandler']}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleReadMore(e);
                        }}
                    />

                    {hasImage && (
                        <div className={styles['ObjectEntry__ImageContainer']}>
                            <div
                                className={styles['ObjectEntry__Image']}
                                style={{
                                    backgroundImage: `url(${image.renditions.small.src})`,
                                    backgroundPosition: `${image.focal.x} ${image.focal.y}`,
                                }}>
                                {date && (
                                    <>
                                        <div
                                            className={
                                                styles[
                                                    'ObjectEntry__DateBackground'
                                                ]
                                            }
                                        />
                                        <time
                                            dateTime={date}
                                            className={
                                                styles[
                                                    'ObjectEntry__DateContainer'
                                                ]
                                            }>
                                            <span
                                                className={classNames(
                                                    styles['ObjectEntry__Date'],
                                                    styles[
                                                        'ObjectEntry__Date--Day'
                                                    ]
                                                )}>
                                                {dayjs(date).format('D')}
                                            </span>
                                            <span
                                                className={classNames(
                                                    styles['ObjectEntry__Date'],
                                                    styles[
                                                        'ObjectEntry__Date--Month'
                                                    ]
                                                )}>
                                                {dayjs(date).format('MMM')}
                                            </span>
                                        </time>
                                    </>
                                )}
                            </div>
                            {!!image.caption && readMore && (
                                <figcaption
                                    className={styles['ObjectEntry__Caption']}>
                                    {i18n.t('generic.photo')} {image.caption}
                                </figcaption>
                            )}
                        </div>
                    )}

                    <div
                        className={classNames(
                            styles['ObjectEntry__ContentContainer'],
                            {
                                [styles[
                                    'ObjectEntry__ContentContainer--NoImage'
                                ]]: !hasImage,
                            }
                        )}>
                        <ActionButtons
                            isMyList={isMyList}
                            showRemoveButton={showRemoveButton}
                            handleRemoveItem={handleRemoveItem}
                            handleAddToMyList={handleAddToMyList}
                            isInMyList={isInMyList}
                            handleOpenModal={handleOpenModal}
                            modalIsOpen={modalIsOpen}
                            handleClose={handleClose}
                            title={title}
                            label={label}
                            href={href}
                            id={id}
                        />

                        <div className={styles['ObjectEntry__Content']}>
                            {title && (
                                <h3 className={styles['ObjectEntry__Title']}>
                                    {title}
                                </h3>
                            )}
                            {label && (
                                <p className={styles['ObjectEntry__Label']}>
                                    {label}
                                </p>
                            )}
                            {dangerousText && (
                                <React.Fragment>
                                    <p
                                        ref={textElement}
                                        className={classNames(
                                            styles['ObjectEntry__Text'],
                                            {
                                                [styles[
                                                    'ObjectEntry__Text--Expanded'
                                                ]]: readMore,
                                            }
                                        )}
                                        dangerouslySetInnerHTML={dangerousText}
                                    />

                                    {showMoreButton && (
                                        <Button
                                            modifiers={[
                                                styles[
                                                    'ObjectEntry__ReadMoreButton'
                                                ],
                                                readMore &&
                                                    styles[
                                                        'ObjectEntry__ReadMoreButton--Expanded'
                                                    ],
                                            ]}
                                            type="tertiary"
                                            icon="chevron"
                                            iconColor="pink"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleReadMore(e);
                                            }}
                                            text={
                                                readMore
                                                    ? i18n.t('generic.less')
                                                    : i18n.t('generic.readMore')
                                            }
                                        />
                                    )}
                                    {readMore && !!massInfo.length && (
                                        <div
                                            className={
                                                styles['ObjectEntry__MassInfo']
                                            }>
                                            {massInfo.map((info, i) => (
                                                <MassInfo key={i} {...info} />
                                            ))}
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
                {readMore && (
                    <div className={styles['ObjectEntry__InfoBoxes']}>
                        {infoBox && <InfoBox {...infoBox} closestStation={closestStation}/>}
                        {infoBoxText && <InfoBox {...infoBoxText} />}
                    </div>
                )}
            </div>
        );
    }
);

ObjectEntry.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    slug: PropTypes.string,
    href: PropTypes.string,
    image: PropTypes.object,
    text: PropTypes.string,
    label: PropTypes.string,
    removeItem: PropTypes.func,
    showRemoveButton: PropTypes.bool,
    isInMyList: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    addToMyList: PropTypes.func,
    isMyList: PropTypes.bool,
    massInfo: PropTypes.array,
    date: PropTypes.string,
};

ObjectEntry.defaultProps = {
    id: null,
    title: null,
    slug: '',
    href: '/',
    image: null,
    text: null,
    label: null,
    removeItem: null,
    showRemoveButton: false,
    active: false,
    onClick: () => {},
    onMouseOver: () => {},
    addToMyList: () => {},
    isMyList: false,
    massInfo: [],
    date: '',
};

const ActionButtons = ({
    isMyList,
    showRemoveButton,
    handleRemoveItem,
    handleAddToMyList,
    isInMyList,
    handleOpenModal,
    modalIsOpen,
    handleClose,
    title,
    label,
    href,
    id,
}) => (
    <div className={styles['ObjectEntry__ButtonContainer']}>
        {isMyList && showRemoveButton && (
            <button
                className={styles['ObjectEntry__RemoveButton']}
                aria-label="Remove"
                type="button"
                size="small"
                onClick={() => {
                    handleRemoveItem(id);
                }}>
                <div className={styles['ObjectEntry__RemoveButtonIcon']}>
                    <CloseIcon />
                </div>
                <div className={styles['ObjectEntry__ButtonLabel']}>
                    {i18n.t('generic.remove')}
                </div>
            </button>
        )}

        {!isMyList && (
            <button
                className={classNames(styles['ObjectEntry__Button'], {
                    [styles['ObjectEntry__Button--BookMarkActive']]: isInMyList,
                })}
                aria-label="Bookmark"
                type="button"
                onClick={handleAddToMyList}>
                <Icon
                    modifiers={[styles['ObjectEntry__ButtonIcon']]}
                    size="large"
                    sizeXLMobile={true}
                    type={isInMyList ? 'bookmarkFilledSmaller' : 'bookmark'}
                    color="black800"
                />
            </button>
        )}

        <button
            className={styles['ObjectEntry__Button']}
            aria-label="Share"
            type="button"
            onClick={handleOpenModal}>
            <Icon
                size="large"
                type="share"
                sizeXLMobile={true}
                color="black800"
            />
        </button>
        <a
            className={styles['ObjectEntry__Button']}
            href={href}
            onClick={(e) => e.stopPropagation()}>
            <span className="sr-only">{title}</span>
        </a>

        <ShareModal
            title={title}
            categories={label}
            isOpen={modalIsOpen}
            url={href}
            handleClose={handleClose}
        />
    </div>
);

export default ObjectEntry;
